import React from "react";
import { useVideosWithIdsQuery } from "@graphql/generated/graphql";
import { YoutubePlayer } from "./player";
import { Trans } from "@lingui/macro";

interface Videos {
  id: string;
  externalId?: string;
  title?: string;
  description?: string;
}

export interface BlockProps {
  videos: Videos[];
}

export const Component: React.FC<BlockProps> = ({ videos }) => {
  const { data, loading, error } = useVideosWithIdsQuery({
    variables: {
      ids: videos.map((video) => video.id),
    },
  });

  return (
    <div className="rich-videos-stack-videos">
      {(loading || error) && (
        <div className="py-64 text-center font-sen text-text-lightest">
          <Trans>Loading...</Trans>
        </div>
      )}
      {data &&
        !error &&
        data.videosWithIds.map((video) => (
          <div key={video.id} className="rounded pb-5 pt-2">
            <YoutubePlayer youtubeId={video.externalId} />
          </div>
        ))}
    </div>
  );
};

export default {
  name: "video",
  inputNames: ["video"],
  defaultProps: {
    videos: [],
  },
  component: Component,
};
