import React from "react";
import { useEffect } from "react";

export interface EmbedBlockProps {
  type: string;
  content: string;
}

declare global {
  interface Window {
    twitter?: any;
    instagram?: any;
    instgrm?: any;
    tiktok?: any;
  }
}

export const EmbedBlock: React.FC<EmbedBlockProps> = ({ type, content }) => {
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];

    const twitterScript = document.getElementById("twitter");
    if (twitterScript) {
      twitterScript.remove();
    }
    const script = document.createElement("script");
    script.id = "twitter";
    script.src = "https://platform.twitter.com/widgets.js";
    body.appendChild(script);

    if (!window.instgrm) {
      const script2 = document.createElement("script");
      script2.src = "//www.instagram.com/embed.js";
      script2.onload = () => {
        window.instgrm.Embeds.process();
      };
      body.appendChild(script2);
      window.instagram = true;
    } else {
      window.instgrm.Embeds.process();
    }

    const tiktokScript = document.getElementById("tiktok");
    if (tiktokScript) {
      tiktokScript.remove();
    }
    const script3 = document.createElement("script");
    script3.id = "tiktok";
    script3.src = "https://www.tiktok.com/embed.js";
    body.appendChild(script3);
  }, []);

  return (
    <>
      {content.includes("youtube") ? (
        <div className="youtube-container w-full rounded">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      ) : (
        <div className={`rich-news-stack-embed ${type} mt-2`}>
          <div className="mx-auto w-[200px] overflow-scroll 2xs:w-[280px] sm:overflow-hidden md:w-[500px]">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default {
  name: "embed",
  defaultProps: {
    type: "",
    content: "",
  },
  component: EmbedBlock,
};
