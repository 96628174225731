import { useCategoriesWithIdsQuery } from "@graphql/generated/graphql";
import Image from "next/image";
import Link from "next/link";
import Label from "@components/shared/label";
import BlueTick from "@public/blue-tick.svg";

interface NewsStack {
  id: string;
  name?: string;
}

export interface BlockProps {
  news_stacks: NewsStack[];
}

export const Component: React.FC<BlockProps> = ({
  news_stacks: newsStacks,
}) => {
  const { data, loading, error } = useCategoriesWithIdsQuery({
    variables: {
      ids: newsStacks.map((stack) => stack.id),
    },
  });

  return (
    <div className="rich-news-stack-news-stacks">
      {!loading && data && (
        <NewsStacksList trendingNewsStack={data.categoriesWithIds} />
      )}
      {loading && <p>Loading</p>}
    </div>
  );
};

const NewsStacksList = ({ trendingNewsStack }) => (
  <div className="space-y-4" id="trending-news-stack">
    {trendingNewsStack.map(
      ({
        id,
        key,
        slug,
        imageUrl,
        exploreName,
        hot,
        updated,
        exclusive,
        author,
        description,
      }) => (
        <div key={id} className="rounded-lg">
          <Link href={`/news-stack/${slug}`} passHref>
            <a>
              <div className="flex flex-row items-center space-x-4">
                <div className="relative h-[150px] w-[120px] shrink-0 lg:h-[282px] lg:w-[255px]">
                  <Image
                    className="rounded-lg"
                    src={imageUrl}
                    alt={key}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <div className="py-2 px-1">
                  {exclusive ? (
                    <div className="py-1">
                      <Label labelName="exclusive" />
                    </div>
                  ) : updated ? (
                    <div className="py-1">
                      <Label labelName="updated" />
                    </div>
                  ) : (
                    hot && (
                      <div className="py-1">
                        <Label labelName="hot" />
                      </div>
                    )
                  )}
                  <p className="mt-2 pr-2 font-poppins font-bold leading-tight text-text-darkest lg:text-xl">
                    {exploreName}
                  </p>
                  {author && (
                    <div>
                      <p className="mt-2 inline-flex font-sen text-sm text-text-dark">
                        {author.name}
                        {author.verified && (
                          <div className="relative ml-1 mt-0.5 inline-flex h-4 w-4">
                            <Image
                              src={BlueTick}
                              alt="blue-tick"
                              layout="fill"
                              objectFit="cover"
                            />
                          </div>
                        )}
                      </p>
                    </div>
                  )}

                  <p className="mt-2 hidden font-sen font-light text-text-medium lg:block">
                    {description}
                  </p>
                </div>
              </div>
            </a>
          </Link>
        </div>
      )
    )}
  </div>
);

export default {
  name: "news_stack",
  defaultProps: {
    news_stacks: [],
  },
  component: Component,
};
