import React from "react";
import { useState } from "react";

export interface ImageBlockProps {
  url: string;
  caption?: string;
}

export const ImageBlock: React.FC<ImageBlockProps> = ({ url, caption }) => {
  return (
    <>
      {/* eslint-disable @next/next/no-img-element */}
      <img src={url} className="rounded-lg" />
      <p className="pt-2 font-poppins text-text-darkest">{caption}</p>
    </>
  );
};
export default {
  name: "image",
  defaultProps: {
    content: "",
  },
  component: ImageBlock,
};
