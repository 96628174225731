import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

export const YoutubePlayer = ({ youtubeId }) => {
  return (
    <div className="youtube-container w-full rounded">
      <LiteYouTubeEmbed id={youtubeId} title="YouTube video player" />
    </div>
  );
};
