import React from "react";
import { useNewsWithIdsQuery } from "@graphql/generated/graphql";
import { WideNewsCard, CompactNewsCard } from "./news-card";
import { Trans } from "@lingui/macro";

interface News {
  id: string;
  title?: string;
  description?: string;
}

export interface BlockProps {
  news: News[];
  mode: "card" | "list";
}

export const Component: React.FC<BlockProps> = ({ news, mode }) => {
  const { data, loading, error } = useNewsWithIdsQuery({
    variables: {
      ids: news.map((news) => news.id),
    },
  });

  return (
    <div className="rich-news-stack-news">
      {(loading || error) && (
        <div className="py-64 text-center font-sen text-text-lightest">
          <Trans>Loading...</Trans>
        </div>
      )}
      <div className="divide-y">
        {data &&
          !error &&
          mode == "list" &&
          data.newsWithIds.map((news) => (
            <div key={news.id} className="py-4">
              <CompactNewsCard news={news} />
            </div>
          ))}
      </div>
      {!loading &&
        data &&
        mode == "card" &&
        (data.newsWithIds.length > 3 ? (
          <div className="divide-y">
            {data.newsWithIds.map((news) => (
              <div key={news.id} className="py-4">
                <CompactNewsCard news={news} />
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full lg:flex lg:flex-row lg:space-x-6">
            {data.newsWithIds.map((news) => (
              <div key={news.id} className="w-full py-4">
                <WideNewsCard news={news} />
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default {
  name: "news",
  inputNames: ["news"],
  defaultProps: {
    news: [],
  },
  component: Component,
};
