import Image from "next/image";
import React from "react";
import Link from "next/link";
import { useWindowWidth } from "@react-hook/window-size";

interface INewsCardProps {
  news: {
    id?: any;
    imageUrl?: any;
    title: any;
    newsPublisher: any;
    pubDate: any;
    good: any;
  };
}

export const CompactNewsCard: React.FC<INewsCardProps> = ({ news }) => {
  const { id, imageUrl, title, newsPublisher, good } = news;
  const width = useWindowWidth();
  const isSmallScreen = width <= 768;
  return (
    <div className="flex flex-row-reverse">
      <div className="flex flex-col items-end">
        <div className="relative h-[80px] w-[75px] 2xs:h-[110px] 2xs:w-[105px] md:h-44 md:w-56">
          <Link href={`/news/${id}`}>
            <a>
              <Image
                className="rounded-lg"
                src={imageUrl}
                alt={title}
                layout="fill"
                objectFit="cover"
                priority
              />
            </a>
          </Link>
        </div>

        <Link href={`/publishers/${newsPublisher.slug}`}>
          <a>
            <div
              className="relative -mt-6 h-8 w-8 md:-mt-10 md:-mr-2 md:h-[48px] md:w-[76px]"
              style={{
                filter: "drop-shadow(1px 1px 8px rgba(147, 154, 188, 0.4)",
              }}
            >
              <Image
                className="rounded-lg"
                src={
                  isSmallScreen ? newsPublisher.iconUrl : newsPublisher.imageUrl
                }
                alt={newsPublisher.name}
                layout="fill"
                objectFit="cover"
                priority
              />
            </div>
          </a>
        </Link>
      </div>

      <div className="ml-4 flex w-full flex-col">
        <Link href={`/publishers/${newsPublisher.slug}`} passHref>
          <a>
            <p className="font-sen text-[10px] font-bold text-text-lightest 2xs:text-xs">
              {newsPublisher.name}
            </p>
          </a>
        </Link>
        <Link href={`/news/${id}`}>
          <a>
            <p className="2xs:text-[16px mt-1 flex-1 pr-6 font-poppins text-[14px] font-bold leading-5 text-text-darkest hover:text-text-dark md:text-xl">
              {title}
            </p>
          </a>
        </Link>

        {good && (
          <div className="my-1 flex">
            <GoodNewsLabel />
          </div>
        )}
      </div>
    </div>
  );
};

export const WideNewsCard = ({ news }) => {
  const { id, imageUrl, title, newsPublisher, good } = news;
  return (
    <>
      <Link href={`/publishers/${newsPublisher.slug}`}>
        <a>
          <div className="mb-4 flex items-center space-x-2">
            <div
              className="relative h-[30px] w-[56px]"
              style={{
                filter: "drop-shadow(1px 1px 8px rgba(147, 154, 188, 0.4)",
              }}
            >
              <Image
                className="rounded-lg"
                src={newsPublisher.imageUrl}
                alt={newsPublisher.name}
                layout="fill"
                objectFit="cover"
                priority
              />
            </div>
            <p className="font-sen text-sm font-bold text-text-darkest">
              {newsPublisher.name}
            </p>
          </div>
        </a>
      </Link>
      <div className="relative h-[179px] w-full">
        <Link href={`/news/${id}`}>
          <a>
            <Image
              className="rounded-lg"
              src={imageUrl}
              alt={title}
              layout="fill"
              objectFit="cover"
              priority
            />
          </a>
        </Link>
      </div>
      <p className="mt-4 font-poppins text-sm font-bold hover:text-text-dark">
        <Link href={`/news/${id}`}>
          <a>{title}</a>
        </Link>
      </p>
      {good && (
        <div className="my-1 flex">
          <GoodNewsLabel />
        </div>
      )}
    </>
  );
};

const GoodNewsLabel: React.FunctionComponent<{}> = () => {
  return (
    <div className="flex items-center justify-center rounded-[4px] bg-green px-2 py-0.5">
      <span className="font-sen text-xs text-white">Good News 🎉</span>
    </div>
  );
};
