export interface TextBlockProps {
  content: string;
}

export const TextBlock: React.FC<TextBlockProps> = ({ content }) => {
  return (
    <div
      className="rich-news-stack-text"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

export default {
  name: "text",
  inputNames: ["content"],
  defaultProps: {
    content: "",
  },
  component: TextBlock,
};
